import React, { useState } from "react"
import AxiosInstance from "../../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Translate from "../../../translationHelper"
import { useEffect } from "react"

const PersonalUsername = () => {
  const [errorMessage, setErrorMessage] = useState("")
  const [status, setStatus] = useState(false)
  const [personalUsername, setPersonalUsername] = useState('')

  const getLoginUserDetail = async () => {
    const loginUserEmail = localStorage.getItem("user_email")
    try {
      const response = await AxiosInstance.get(`/user/login-user-detail/${loginUserEmail}`)
      if (response.status === 200) {
        if(response.data.user.personalUsername) {
          setPersonalUsername(`${response.data.user.personalUsername}.${response.data.user.username}`)
        }   
      }
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const handleUserNameCheck = e => {
    if (e.target.value === "") {
      setStatus(false)
    }

    const loginUserId = localStorage.getItem("user_id")
    AxiosInstance.post(`/user/update-personal-username/${loginUserId}`, {
      personalUsername: e.target.value,
    })
      .then(responce => {
        if (responce.status == 200) {
          setErrorMessage("Valid.")
          setStatus(true)
        }
      })
      .catch(error => {
        if (error.response.status == 400) {
          setStatus(false)
          setErrorMessage("Invalid.")
        }
      })
  }

  useEffect(() => {
    getLoginUserDetail()
  }, [])

  return (
    <div className="row adbook-user-other-info align-items-center py-3 border-top border-bottom">
      <div className="col-md-4 text-md-end text-start">
        <label>{Translate("Personal Username")}</label>
      </div>
      <div className="col-md-2 text-center privacy-col">
        <i className="bi bi-globe" />
      </div>
      <div className="col-md-4 inputbox">
        <div className="input-group usernameInputContainer">
          <div className="input-group-text">@</div>
          <input
            type="text"
            className="form-control usernameInput "
            placeholder="Username"
            name="username"
            value={personalUsername}
            // onKeyUpCapture={handleUserNameCheck}
            readOnly={true}
          />

          {errorMessage && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip className="ms-1 d-inline-block">{errorMessage}</Tooltip>}
            >
              <span className="validationErrorMsg ">
                {status === true && <i className="bi bi-check2 text-success"></i>}
                {status === false && <i className="bi bi-x-lg "></i>}
              </span>
            </OverlayTrigger>
          )}
        </div>
      </div>
    </div>
  )
}

export default PersonalUsername
